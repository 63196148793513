<template>

<app-content-box title="Settings" icon="settings" :transparent="true" :freeze="status === $constants.status.loading">

	<app-input-toggle :slim="true" :fullWidth="true" label="Time format" v-model="model.timeformat" :options="timeOptions" />

	<app-input-toggle :slim="true" :fullWidth="true" label="Notification emails" v-model="model.notifications" :options="yesNoOptions" />

	<app-input-toggle :slim="true" :fullWidth="true" label="Buddies Enabled" v-model="model.directory" :options="yesNoOptions" />

	<app-button text="Save" :fullWidth="true" v-on:click="onSubmitClick" :loading="status === $constants.status.loading" />

</app-content-box>

</template>

<script>

export default {

	data: function() {

		return {
			status: false,
			model: {
				timeformat: 0,
				notifications: 1,
				directory: 1
			},
			timeOptions: {
				0: '12hr',
				1: '24hr'
			},
			yesNoOptions: {
				0: 'No',
				1: 'Yes'
			}
		}

	},
	
	created: function() {

		this.model.timeformat = this.$store.getters['session/settings'].timeformat
		this.model.notifications = this.$store.getters['session/settings'].notifications
		this.model.directory = this.$store.getters['session/settings'].directory

	},

	methods: {

		onSubmitClick: function() {

			this.status = this.$constants.status.loading

			this.$api.put('session/settings', this.model).then(function() {

				this.status = this.$constants.status.success

				this.$notify({
					message: ['You have successfully saved your settings.']
				})

			}.bind(this), function() {

				this.status = this.$constants.status.failed

				this.$notify({
					message: ['Sorry, your settings could not be saved. Please try again.'],
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>
